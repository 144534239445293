import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { productData } from "../../constants/data";
import Button from "../HireUs/HireUsButton";

const Portfolio = () => {
  return (
    <div className=" w-[100vw] res-cont flex flex-col">
      <h1 className="small-heading">PORTFOLIO</h1>
      <h1 className="heading">These are few of our completed projects.</h1>
      <div className="   items-center place-self-center  grid grid-cols-1 md:grid-cols-2  justify-between  md:gap-10 ">
        {productData.map((item, index) => (
          <div key={index} className="mt-4 md:w-[500px] shadow-sm rounded">
            <img   src={item.img} alt="Product" className=" image   w-[100%]" />
            <h1 className=" ml-4 text-lg font-semibold text-customBlue py-3">
              {item.title}
            </h1>
            <p className="ml-4 text-customBlue text-sm">{item.desc}</p>
            <div className="p-3 w-[100%] flex justify-center items-center">

<Button link={item.url}  text={(<><FaExternalLinkAlt /></>)} />
</div>
          
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default Portfolio;
